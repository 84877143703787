.widget-area {
	.menu,
	.nav {
		@extend %sidebar-nav;

		.menu-item {
			&-anchor {
				display: flex;
				align-items: center;

				@include mq( tablet ) {
					&:not(:hover) {
						.label {
							margin-left: $base-spacing-unit-tiny;
							border-radius: 3px;
							max-width: $base-spacing-unit-tiny;
							height: $base-spacing-unit-tiny;
							padding: 0;
							color: rgba(255,255,255,0);
						}
					}
				}
			}

			&-icon {
				margin-right: $base-spacing-unit-small;
				font-size: 1.5em;
				display: inline-flex;

				@include mq( $until: tablet ) {
					font-size: 1.1em;
				}
			}

			&-icon,
			&-link {
				vertical-align: middle;
			}

			&-link {
				flex-grow: 1;

				& + .label:last-child {
					border-radius: 3px;
				}
			}

			&-label {
				transition: all 0.2s cubic-bezier(.57,.01,.76,.55);
				min-width: auto;
				border-radius: 3px;
				max-width: 100px;
				height: 20px;

				&:not(:last-child) {
					border-radius: 3px 0 0 3px;
				}

				& + .label {
					border-radius: 0;
				}

				&:last-child {
					border-radius: 0 3px 3px 0;
				}
			}
		}
	}
}