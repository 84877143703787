.elementor-section {
	&.elementor-section-boxed {
		@extend .o-wrapper;

		> .elementor-container {
			@extend .o-wrapper__content;

			.elementor-container {
				width: auto;
			}
		}
	}
}