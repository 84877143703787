/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/**
 * Grid-like layout system.
 *
 * The Layout object provides us with a column-style layout system. This file
 * contains the basic structural elements, but classes should be complemented
 * with width Utilities, for example:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-width-1/2">
 *     </div>
 *     <div class="o-layout__item  u-width-1/2">
 *     </div>
 *   </div>
 *
 * The above will create a two-column structure in which each column will
 * fluidly fill half of the width of the parent. We can have more complex
 * systems:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-width-1/1  u-width-1/3@medium">
 *     </div>
 *     <div class="o-layout__item  u-width-1/2  u-width-1/3@medium">
 *     </div>
 *     <div class="o-layout__item  u-width-1/2  u-width-1/3@medium">
 *     </div>
 *   </div>
 *
 * The above will create a system in which the first item will be 100% width
 * until we enter our medium breakpoint, when it will become 33.333% width. The
 * second and third items will be 50% of their parent, until they also become
 * 33.333% width at the medium breakpoint.
 *
 * We can also manipulate entire layout systems by adding a series of Modifiers
 * to the `.o-layout` Block. For example:
 *
 *   <div class="o-layout  o-layout--reverse">
 *
 * This will reverse the displayed order of the system so that it runs in the
 * opposite order to our source, effectively flipping the system over.
 *
 *   <div class="o-layout  o-layout--[right|center]">
 *
 * This will cause the system to fill up from either the centre or the right
 * hand side. Default behaviour is to fill up the layout system from the left.
 *
 * There are plenty more options available to us: explore them below.
 */

// By default we use the `font-size: 0;` trick to remove whitespace between.
// Set this to true in order to use a markup-based strategy like commenting out
// whitespace or minifying HTML.
$use-markup-fix: false !default;
/* Default/mandatory classes.
   ========================================================================== */
/**
 * 1. Allows us to use the layout object on any type of element.
 * 2. We need to defensively reset any box-model properties.
 * 3. Use the negative margin trick for multi-row grids:
 *    http://csswizardry.com/2011/08/building-better-grid-systems/
 */

.o-layout {
    display: block;
    /* [1] */
    
    margin: 0;
    /* [2] */
    
    padding: 0;
    /* [2] */
    
    list-style: none;
    /* [1] */
    
    margin-left: -$base-spacing-unit;
    /* [3] */
    
    @if ($use-markup-fix == false) {
        font-size: 0;
    }
}
/**
   * 1. Allows us to manipulate grids vertically, with text-level properties,
   *    etc.
   * 2. Default item alignment is with the tops of each other, like most
   *    traditional grid/layout systems.
   * 3. By default, all layout items are full-width (mobile first).
   * 4. Gutters provided by left padding:
   *    http://csswizardry.com/2011/08/building-better-grid-systems/
   */

.o-layout__item {
    display: inline-block;
    /* [1] */
    
    vertical-align: top;
    /* [2] */
    
    width: 100%;
    /* [3] */
    
    padding-left: $base-spacing-unit;
    /* [4] */
    
    @if ($use-markup-fix == false) {
        @if ($oldIE= true) {
            font-size: $base-font-size;
        }
        font-size: 1rem;
    }
}

.o-layout--flexbox {
    display: flex;
    flex-wrap: wrap;

    > .o-layout__item {
        flex-grow: 0;
    }
}

/* Gutter size modifiers.
   ========================================================================== */

/**
 * Tiny gutters between items.
 */

.o-layout--tiny {
    margin-left: -$base-spacing-unit-tiny;
    > .o-layout__item {
        padding-left: $base-spacing-unit-tiny;
    }
}
/**
 * Small gutters between items.
 */

.o-layout--small {
    margin-left: -$base-spacing-unit-small;
    > .o-layout__item {
        padding-left: $base-spacing-unit-small;
    }
}
/**
 * Large gutters between items.
 */

.o-layout--large {
    margin-left: -$base-spacing-unit-large;
    > .o-layout__item {
        padding-left: $base-spacing-unit-large;
    }
}
/**
 * Huge gutters between items.
 */

.o-layout--huge {
    margin-left: -$base-spacing-unit-huge;
    > .o-layout__item {
        padding-left: $base-spacing-unit-huge;
    }
}
/**
 * No gutters between items.
 */

.o-layout--flush {
    margin-left: 0;
    > .o-layout__item {
        padding-left: 0;
    }
}
/* Vertical alignment modifiers.
   ========================================================================== */
/**
 * Align all grid items to the middles of each other.
 *
 * Input:
 *
 *   1 2 3 4 5
 *   1 2 - 4 5
 *   1 2 - 4 5
 *   - 2 - - 5
 *   - 2 - - 5
 *
 * Output:
 *
 *   - 2 - - 5
 *   1 2 - 4 5
 *   1 2 3 4 5
 *   1 2 - 4 5
 *   - 2 - - 5
 */

.o-layout--middle {
    > .o-layout__item {
        vertical-align: middle;
    }
}
/**
 * Align all grid items to the bottoms of each other.
 *
 * Input:
 *
 *   1 2 3 4 5
 *   1 2 - 4 5
 *   1 2 - 4 5
 *   - 2 - - 5
 *   - 2 - - 5
 *
 * Output:
 *
 *   - 2 - - 5
 *   - 2 - - 5
 *   1 2 - 4 5
 *   1 2 - 4 5
 *   1 2 3 4 5
 */

.o-layout--bottom {
    > .o-layout__item {
        vertical-align: bottom;
    }
}
/* Fill order modifiers.
   ========================================================================== */
/**
 * Fill up the layout system from the centre.
 *
 * Input:
 *
 *   1 2 3 - -
 *
 * Output:
 *
 *   - 1 2 3 -
 */

.o-layout--center {
    text-align: center;
    > .o-layout__item {
        text-align: left;
    }
}
/**
 * Fill up the layout system from the right-hand side.
 *
 * Input:
 *
 *   1 2 3 - -
 *
 * Output:
 *
 *   - - 1 2 3
 */

.o-layout--right {
    text-align: right;
    > .o-layout__item {
        text-align: left;
    }
}
/**
 * Reverse the rendered order of the grid system.
 *
 * Input:
 *
 *   1 2 3 4 5
 *
 * Output:
 *
 *   5 4 3 2 1
 */

.o-layout--reverse {
    direction: rtl;
    > .o-layout__item {
        direction: ltr;
        text-align: left;
    }
}