%data-list {
	@extend .nav;
	@extend .nav--stacked;

	margin: 0;

	li {
		color: $body-color;
		border-bottom: 1px solid $gray-light;

		padding-top: $base-spacing-unit-small;
		padding-bottom: $base-spacing-unit-small;
		margin: 0;

		&:last-child {
			border-bottom: 0px;	
		}
	}
}

.data-list {
	@extend %data-list;
}