/* ==========================================================================
   #PREFIX
   ========================================================================== */

.o-prefix {
	display: flex;

	&__content {
		flex-grow: 1;
		margin: 0;
	}

	&__action {
		display: flex;
	}
}