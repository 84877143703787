.switcher {
	display: inline-flex;
	position: relative;

	border: 1px solid $gray-light;
	border-radius: $base-radius;

	margin-left: $base-spacing-unit-small;

	cursor: pointer;

	&__on,
	&__off {
		flex-basis: 50%;
		padding: 4px 10px;
		text-align: center;
	}

	&:after {
		content: "";

		border-radius: $base-radius;

		background-color: $alert-color;
		
		position: absolute;
		left: 3px;
		top: 3px;
		bottom: 3px;

		width: calc(50% - 3px);

		transition: $base-transition-linear;

	}
}

.switcher-label {
	margin-bottom: $base-spacing-unit-small;
}

.switcher-wrapper {
	display: inline-block;
	margin: 0;

	input[type=checkbox] {

		&:checked {
			& + .switcher {
				border-color: $gray-dark;

				&:after {
					left: 50%;

					background-color: $secondary-color;
				}
			}
		}
	}
}