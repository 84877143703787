/* ==========================================================================
   #FLEXBOX
   ========================================================================== */

.o-flexbox {
	display: block;
	display: flex;
	flex-wrap: wrap;
	
	.o-layout__item {
		// flex-grow: 1;
		// Fix three column layout on desktop
		// Targets last item of three column grids amd forces
		// it to stack on desktop
	}
}

// Equal height items
.o-flexbox--equalize {
	> .o-layout__item {
		display: flex;

		&.flex-column {
			flex-direction: column;
		}

		> * {
			flex-grow: 1;
		}
	}
}

// Align items to middle vertically
.o-flexbox--middle {
	align-items: center;
}

// Align items to middle vertically
.o-flexbox--center {
	justify-content: center;
	
	> .o-layout__item {
		flex-grow: 0;
	}
}