.ee-offcanvas {
	&__content {
		.menu {
			border: 0px;
			box-shadow: none;
		}

		.widget-title {
			padding-left: $base-spacing-unit-small;
			margin-bottom: $base-spacing-unit-small;
		}
	}
}