body.skin-kentledge {
	a { color: $kl-secondary; }

	.site-header {
		&.is--attached {
			@extend .b--kl-primary;
		}
	}

	label {
		&.label--radio,
		&.label--checkbox {
			&:after {
				background-color: $kl-primary;
			}

			&.is--checked {
				&:before {
					border-color: $kl-primary;
				}
			}
		}
	}

	#mc-embedded-subscribe {
		@extend .b--kl-secondary;
	}

	.typster {
		@include mq( $until: tablet ) {
			display: none;
		}
		
		display: block;

		&__controls {
			// margin-bottom: $base-spacing-unit !important;
		}

		&-preview {
			color: $kl-primary;

			&::selection { background: $kl-primary; color: $white; }
			&::-moz-selection { background: $kl-primary; color: $white; }

			&,
			&:focus {
				color: $kl-primary;
				border-top-color: $kl-primary;
				border-bottom-color: $kl-primary;
			}
		}

		&-control {

			label {
				span {
					color: $kl-secondary;
				}
			}
			&--weight {
				ul {
					li {

						vertical-align: middle;
						cursor: pointer;

						&:hover,
						&.is--selected {
							span {
								background-color: $kl-primary;
							}
						};

						span {
							background-color: transparentize($kl-primary, 0.5);
						}
					}
				}
			}
		}
	}

	.rangeslider {
		background: $kl-primary;
	}

	.rangeslider__fill {
		background: $kl-primary;
	}

	.rangeslider__handle {
			background: $kl-primary;
		}
}

body.skin-willie {
	a { color: $wl-primary; }

	.site-header {
		&.is--attached {
			@extend .b--wl-primary;
		}
	}

	label {
		&.label--radio,
		&.label--checkbox {
			&:after {
				background-color: $wl-primary;
			}

			&.is--checked {
				&:before {
					border-color: $wl-primary;
				}
			}
		}
	}

	#mc-embedded-subscribe {
		@extend .b--wl-primary;
	}

	.typster {
		@include mq( $until: tablet ) {
			display: none;
		}
		
		display: block;

		&__controls {
			// margin-bottom: $base-spacing-unit !important;
		}

		&-preview {
			color: $wl-primary;

			&::selection { background: $wl-primary; color: $white; }
			&::-moz-selection { background: $wl-primary; color: $white; }

			&,
			&:focus {
				color: $wl-primary;
				border-top-color: $wl-primary;
				border-bottom-color: $wl-primary;
			}
		}

		&-control {

			label {
				span {
					color: $wl-primary;
				}
			}
			&--weight {
				ul {
					li {

						vertical-align: middle;
						cursor: pointer;

						&:hover,
						&.is--selected {
							span {
								background-color: $wl-primary;
							}
						};

						span {
							background-color: transparentize($wl-primary, 0.5);
						}
					}
				}
			}
		}

		.rangeslider {
			background: $wl-secondary;
		}

		.rangeslider__fill {
			background: $wl-primary;
		}

		.rangeslider__handle {
			background: $wl-primary;
		}
	}
}