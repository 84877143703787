// Default configuration for typeline
$reType_config: (
	points: (0 1, 50 1.6, 200 1.8),
	breakpoints: 1300px 980px 740px 520px,
	rules: (
		'%h0' 			: 110px,
		'%big'			: 78px,
		'%html'			: $base-font-size,
		'%body'			: $base-font-size,
		'%h1'			: 48px,
		'%h2'			: 36px,
		'%h3'			: 24px,
		'%h4'			: 20px,
		'%h5'			: 18px,
		'%h6'			: 16px,
		'%intro'		: 18px,
		'%small'		: 14px,
		'%tiny'			: 12px,
	),
) !default;

$reType_points: 		map-get($reType_config, points) 		!default;
$reType_breakpoints: 	map-get($reType_config, breakpoints) 	!default;
$reType_rules: 			map-get($reType_config, rules) 			!default;

$A: nth($reType_points, 1);
$B: nth($reType_points, 2);
$C: nth($reType_points, 3);

@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}
	@return $number;
}

@function pow($base, $exponent) {
    // reset value
    $value: $base;
    // positive intergers get multiplied
    @if $exponent > 1 {
        @for $i from 2 through $exponent {
            $value: $value * $base;
        }
    }
    // negitive intergers get divided. A number divided by itself is 1
    @if $exponent < 1 {
        @for $i from 0 through -$exponent {
            $value: $value / $base;
        }
    }
    // return the last value written
    @return $value;
}

// returns Y value for a given font-size for the first breakpont
// according to the given variable values
@function getY($fontSize) {
	$fontSize: strip-unit($fontSize);
	@if $fontSize < nth($B, 1) {
		// if given fontSize is smaller then B.x
		// use an exponential function to determine Y value [ ie. y = ab^(x^3) ]
		$a: nth($A, 2);
		$b: (nth($B, 2) - nth($A, 2)) / pow(nth($B, 1), 3);
		@return $a + $b * pow($fontSize, 3);
	} @else {
		// otherwise use a basic linear equation solving method
		@return nth($B, 2) + (nth($C, 2) - nth($B, 2)) * ($fontSize - nth($B, 1)) / (nth($C, 1) - nth($B, 1));
	}
}

// used to get the actual font-size for a given breakpoint
@function getFontSize($fontSize, $breakpoint: false) {

	$index: index($reType_breakpoints, $breakpoint);
	@if $index == null {
		@return $fontSize;
	}

	// we need to find values that are equally spread across the interval [1, getY($fontSize)] for each breakpoint we have
	$y: getY($fontSize);
	$ratio: ($y - 1) * $index / length($reType_breakpoints) + 1;
	@return round($fontSize / $ratio);
}

//
@each $selector, $value in $reType_rules {
	#{$selector} {
		font-size: $value;
	}
}

@each $breakpoint in $reType_breakpoints {
	@media only screen and (max-width: $breakpoint) {
		@each $selector, $value in $reType_rules {
			#{$selector} {
				font-size: getFontSize($value, $breakpoint);
			}
		}
	}
}
