.ee-tooltip {
	position: absolute;
	z-index: 999;

	padding: $base-spacing-unit-small;

	background-color: $primary-color;
	color: #FFFFFF;

	border-radius: $base-radius;

	p:last-child {
		margin: 0;
	}

	&:after {
		content: '';
		position: absolute;

		width: 0;
		height: 0;

		left: 50%;
		bottom: -10px;
		margin-left: -10px;

		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid transparent;

		border-top-color: $primary-color;
	}

	&.to--bottom {
		&:after {
			top: -10px;
			bottom: auto;

			border-top: 0px;
			border-bottom-width: 10px;
			border-bottom-style: solid;
			border-bottom-color: $primary-color;
		}

		&.at {
			&--left {
				&:after {
					border-left-color: $primary-color;
				}
			}

			&--right {
				&:after {
					border-right-color: $primary-color;
				}
			}
		}
	}

	&.to--left {
		&:after {
			right: -10px;
			left: auto;
			bottom: auto;

			top: 50%;

			margin: 0;
			margin-top: -10px;

			border-right: 0px solid transparent;

			border-left: 10px solid $primary-color;
			border-bottom: 10px solid transparent;
			border-top: 10px solid transparent;
		}

		&.at {
			&--top {
				&:after {
					border-top-color: $primary-color;
				}
			}

			&--bottom {
				&:after {
					border-bottom-color: $primary-color;
				}
			}
		}
	}

	&.to--right {
		&:after {
			left: -10px;
			right: auto;
			bottom: auto;

			top: 50%;

			margin: 0;
			margin-top: -10px;

			border-left: 0px solid transparent;

			border-right: 10px solid $primary-color;
			border-bottom: 10px solid transparent;
			border-top: 10px solid transparent;
		}

		&.at {
			&--top {
				&:after {
					border-top-color: $primary-color;
				}
			}

			&--bottom {
				&:after {
					border-bottom-color: $primary-color;
				}
			}
		}
	}

	&.to--top {
		&:after {
			bottom: -10px;
			top: auto;

			border-bottom: 0px;
			border-top-color: $primary-color;
		}

		&.at {
			&--left {
				&:after {
					border-left-color: $primary-color;
				}
			}

			&--right {
				&:after {
					border-right-color: $primary-color;
				}
			}
		}
	}

	&.at--top {
		&:after {
			bottom: auto;
			top: 0;
		}
	}

	&.at--bottom {
		&:after {
			top: auto;
			bottom: 0;
		}
	}

	&.at--left {
		&:after {
			left: 0;
		}
	}

	&.at--right {
		&:after {
			right: 0;
			left: auto;
		}
	}

	&.at {
		&--top,
		&--right,
		&--bottom,
		&--left, {
			&:after {
				margin: 0;
				border: 10px solid transparent;
			}
		}
	}
}