/* ==========================================================================
   #IMAGES
   ========================================================================== */

/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  max-width: 100%; /* [1] */
  height: auto;
  font-style: italic; /* [2] */
  vertical-align: middle; /* [3] */
  image-rendering: -webkit-optimize-contrast;
}
	
/**
 * Make SVG images responsive
 *
 * 1. Limit to with no real width and height values (as added by wordpress)
 */
img[src*=".svg"][width="1"][height="1"] { /* [1] */
	width: 100% !important;
	height: auto !important;
}

// General icons inside links
a {
	> .fa {
		&:first-child {
			margin-right: $base-spacing-unit-small;
		}
		&:last-child {
			margin-left: $base-spacing-unit-small;
		}
	}
}