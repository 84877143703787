.edd-table {
	@extend %table;
	@extend .alt-rows;
}

#edd_discounts_list {
	@extend .o-list-bare;
}

.edd_form {
	p { margin-bottom: $base-spacing-unit-small; }
}

#edd_profile_submit_wrap {
	margin: 0;
}

.edd-description {
	@extend %tiny;

	display: block;
	color: $gray-dark;
	margin-bottom: $base-spacing-unit-tiny;
}

.edd-label {
	& + .edd-description {
		margin-top: -$base-spacing-unit-tiny;
	}
}

.edd_download_purchase_form {
	@extend %box;
	background-color: $white;

	margin-bottom: 0;

	@include clearfix();

	.edd_price_options {
		
		ul {
			@extend %data-list;

			li { padding: 0; }

			label {
				margin: 0;
				padding: $base-spacing-unit-small;

				transition: $base-transition-linear;

				&.is--checked {
					background-color: $gray-lighter;
				}

				&:after {
					left: $base-spacing-unit-small;
					top: 50%;
				}
			}
		}
	}

	.edd_download_quantity_wrapper {
		padding: $base-spacing-unit-small;
		padding-right: 0;
		border-top: 1px solid $gray-light;
	}

	.edd_price_option_name {
		font-weight: 600;
	}

	.edd_price_option_price {
		@extend %intro;
		
    	letter-spacing: -0.05em;
	}

	.edd_purchase_submit_wrapper {

		padding: $base-spacing-unit-small;

		border-top: 1px solid $gray-light;
		border-bottom: 1px solid $gray-light;
		border-bottom: 0px;

		.edd_purchase_tax_rate {
			display: block;

			@extend %small;
			color: $gray-dark;

			margin-top: $base-spacing-unit-tiny;
		}
	}

	.edd-sl-upgrade-link {
		display: block;
		padding: $base-spacing-unit-small;
		border-top: 1px solid $gray-light;

		@extend .f--body;

		a:hover {
			color: $secondary-color !important;
		}

		& + * {
			margin-left: $base-spacing-unit-small;
			padding-left: 0;
		}
	}

	.edd-add-to-cart,
	.edd_go_to_checkout {
		@extend .b--secondary;
		@extend .button--large;

		margin-right: $base-spacing-unit-small;
	}
}

.edd_price_option_sep {
	color: $gray-dark;
}

// Notifications
.edd_errors {
	&.edd-alert-error {
		.edd_error {
			@extend .notification-error;
		}
	}
}

.edd_error {
	@extend .notification;

	word-break: break-all;
	display: block;
}

.edd-loading-ajax {
	&.edd-loading {
		@extend %spinner;

		margin: 0 auto;
	}
}

body.skin-kentledge {
	.edd_download_purchase_form {
		.edd-add-to-cart,
		.edd_go_to_checkout {
			@extend .b--kl-primary;
		}
	}
}