#edd_checkout_wrap {

	.edd {
		&_error {
			margin-bottom: $base-spacing-unit;
		}
	}

	#edd_checkout_cart_form {
		.edd {
			&_cart {
				&_item {
					&_price {
						text-align: right;

						em { @extend %small; }
					}

					&_image {
						display: none;
					}

					&_name {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
					}

					&_remove {
						@extend %tiny;
					}
				}

				&_actions {
					display: none;

					// > * {
					// 	@extend .button;
					// 	@extend .button--tiny;
					// }

					// .edd_cart_remove_item_btn {
					// 	@extend .button--tiny;
					// 	@extend .b--gray-lighter;
					// }
				}

				&_footer {
					&_row {
						text-align: right;

						.button {
							@extend .button--tiny;
						}
					}
				}

				&_total {
					text-align: left;
				}
			}
		}

		.edd-sl {
			&-renewal-details {
				@extend .notification;
				@extend .notification-success;
				@extend .notification--small;

				margin-top: $base-spacing-unit-tiny;

				.edd-sl-renewal-key {
					font-weight: 600;
				}
			}
		}
	}

	#edd_checkout_form_wrap {

		label.label {

			&--checkbox {
				font-size: $base-font-size;
			}
		}

		#edd-license-key-container-wrap {
			
		}

		#edd_show_discount {
			margin: 0;
		}

		#edd_discount_code {
			padding: $base-spacing-unit-small;

			.edd-input[name="edd-discount"] {
				margin-bottom: $base-spacing-unit-small;
			}

			.edd-apply-discount {
				width: 100%;
			}
		}

		.edd-sl-renewal-actions {
			display: flex;
			align-items: center;

			font-size: 0;
			margin-left: -$base-spacing-unit-small;
			margin-bottom: $base-spacing-unit-small;

			> * {
				font-size: 1rem;
				margin-left: $base-spacing-unit-small;
			}
		}

		#edd_sl_renewal_fields {
			padding: $base-spacing-unit-small;
			background-color: transparent;

			#edd_sl_show_renewal_form_wrap {
				margin: 0;
			}
		}

		#edd-cancel-license-renewal {
			@extend .button;
			@extend .b--alert;
		}

		#edd-payment-mode-wrap {
			label.label {
				&--radio {
					margin-bottom: $base-spacing-unit-small;
				}
			}
		}
	}

	.edd-payment-icons {
		@extend .nav;
		@extend .nav--banner;
		@extend .nav-space;

		.payment-icon {
			margin-left: $base-spacing-unit;
			max-width: $base-spacing-unit;
		}

		margin-bottom: $base-spacing-unit;
	}

	#edd {
		&_checkout_user_info {
			#edd-first-name-wrap,
			#edd-last-name-wrap {
				@extend %edd-form-columns;
			}
		}

		&_purchase {
			&_submit {
				#edd-purchase-button {
					@extend .b--secondary;
				}
			}
		}
	}

	#edd_cc_fields {
		#edd_secure_site_wrapper {
			margin-bottom: $base-spacing-unit;
			> * {
				display: inline-block;
				vertical-align: middle;
			}

			.padlock {
				margin-right: $base-spacing-unit-small;
			}
		}

		#edd-card-number-wrap,
		#edd-card-cvc-wrap {
			@extend %edd-form-columns;
		}

		.exp-divider { display: none; }

		.card-expiry-month,
		.card-expiry-year {
			@extend %edd-form-columns;
		}

		.card-expiry-year {
			@include mq( tablet ) {
				margin-left: $base-spacing-unit;
			}
		}
	}

	#edd_cc_address {
		#edd-card-country-wrap,
		#edd-card-state-wrap {
			@extend %edd-form-columns;
		}
	}

}

#edd_checkout_cart {
	@extend .alt-rows;

	@extend %small;

	.edd_cart_total {
		.edd_cart_amount {
			display: block;
		}
	}

	tfoot {
		tr {
			th {
				> span { float: right; }
			}
		}
	}
}

#edd-discount-error-wrap {
	border: 0px;
	background: transparent;
	padding: 0;

	margin-top: $base-spacing-unit;
}