// Admin bar tweaks

body.admin-bar {
	.site-header {
		&.floating {
			margin-top: 32px;

			@include mq( $until: desktop ) {
				margin-top: 46px;
			}
		}
	}
	.off-canvas {
		top: 46px;

		@include mq( desktop ) {
			top: 32px;
		}
	}
}

.entry-content {
	p {
		font-size: 18px;
	}
}

.entry-header {
	margin-bottom: $base-spacing-unit;
}