.edd-pricing-table {
	@extend .o-layout;
	@extend .o-layout--large;

	.edd-price-option {
		@extend .o-layout__item;

		@include mq( $until: tablet ) {
			margin-bottom: $base-spacing-unit;
		}

		@include mq(tablet) {
			width: 100 / 3 + %;
		}

		> div {
			@extend %box;

			text-align: center;

			background-color: $white;

			transition: $base-transition-linear;
			transition-duration: 0.3s;

			&:hover {
				@extend %large-shadow;
			}
		}

		&.featured {
			> div {
				border: 1px solid $secondary-color;
			}
		}

		.edd-pt-title,
		.edd-pt-description,
		.featured-text,
		.footer {
			@extend .o-box;
		}

		.featured-text {
			display: none;
		}

		.edd-pt-title {
			display: block;

			@extend .h3;
		}

		ul {
			@extend %data-list;

			.pricing {
				.price {
					@extend .h3;

					display: block;
				}

				.period {
					display: block;
					color: $gray-dark;
				}
			}
		}
	}
}