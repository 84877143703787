$spacing-distances : (
	'--tiny' 		: $base-spacing-unit-tiny,
	'--small'		: $base-spacing-unit-small,
	null 			: $base-spacing-unit,
	'--large'		: $base-spacing-unit-large,
	'--huge'		: $base-spacing-unit-huge,
) !default;

.o-spacer {
	@include clearfix();
}
// Creates a spacing object that adds padding top and bottom
// Ex .spacer--huge

@if(variable-exists(spacing-distances)) {
	@each $spacing, $spacing-value in $spacing-distances {
		.o-spacer#{$spacing} {
			position: relative;
			padding: $base-spacing-unit-large 0;

			*[class^="spacer"] {
				overflow: visible;
			}

			.o-spacer__header {
				padding-bottom: $spacing-value / 2;
			}

			.o-spacer__footer {
				padding-top: $spacing-value / 2;
			}

			@include mq( tablet ) {
				padding: $spacing-value 0;
			}
		}
	}
} @else {
	@error 'Variable $spacing-distances does not exists';
}
