.edd-table-responsive {
	@include mq( $until: tablet ) {
		thead tr, th {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		td[data-title] {
			@include mq( $until: tablet ) {
				display: flex;

				&:before {
					content: attr( data-title );
					flex: 0 0 50%;
					font-weight: 600;
				}

				&[class*="-actions"] {
					&:before {
						content: none;
					}
				}
			}
		}
	}

}