// Components
$button-sizes: (
	small tiny default large huge
) !default;

%button {
	font-family: $font-body;
	display: inline-block;

	border: 0px;
	border-radius: $base-radius;
	cursor: pointer;

	transition: $base-transition-linear;
	letter-spacing: -0.02em;

	font-weight: $font-weight-buttons;

	@extend .button--default;
	@extend .b--primary;

	&:hover {
		box-shadow: $base-shadow;
	}

	&--icon {
		display: inline-flex;
		align-items: center;
	}
}

%button--tiny,
.button--tiny {
	font-size: 12px;
	padding: 5px 10px;
}

%button--small,
.button--small {
	font-size: 14px;
	padding: 7px 12px;
}

%button--default,
.button--default {
	font-size: $base-font-size;
	padding: 9px 14px;
}

%button--large,
.button--large {
	font-size: 18px;
	padding: 11px 20px;
}

%button--huge,
.button--huge {
	font-size: 20px;
	padding: 14px 22px;
}


button, .button,
input[type="submit"],
input[type="reset"] {
	@extend %button;
}

.o-prefix__action {
	& %button, &%button {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
}