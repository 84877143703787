table.edd-cart {
	tfoot {
		border-top: 1px solid $gray-light;
		
		tr {
			background-color: transparent;
		}
	}
}

.edd-checkout-wrapper {
	max-width: 400px;
	width: 100%;
	float: right;
	padding: $base-spacing-unit-small;

	@include mq( desktop ) {
		padding: $base-spacing-unit;
	}

	table {
		width: 100%;
	}
}