
.rangeslider,
.rangeslider__fill {
	display: block;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

.rangeslider {
	@include clearfix();
	margin-top: 8px + $base-spacing-unit;
	float: left;
	background: $primary-color;
	position: relative;
}

.rangeslider--horizontal {
	height: 2px;
	width: 100%;
}

.rangeslider--vertical {
	width: 20px;
	min-height: 150px;
	max-height: 100%;
}

.rangeslider--disabled {
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
	opacity: 0.4;
}

.rangeslider__fill {
	background: $primary-color;
	position: absolute;
}
.rangeslider--horizontal .rangeslider__fill {
	top: 0;
	height: 100%;
}
.rangeslider--vertical .rangeslider__fill {
	bottom: 0;
	width: 100%;
}

.rangeslider__handle {
	background: $kl-primary;
	cursor: pointer;
	display: inline-block;
	width: 16px;
	height: 16px;
	position: absolute;
	
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.rangeslider__handle:after {
	content: "";
	display: block;
	width: 18px;
	height: 18px;
	margin: auto;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.rangeslider--horizontal .rangeslider__handle {
	top: -7px;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}
.rangeslider--vertical .rangeslider__handle {
	left: -7px;
	touch-action: pan-x;
	-ms-touch-action: pan-x;
}