///* ========================================================================
//   #CORE
//   ======================================================================== */

// This core file sets up inuitcss’ most important setup variables. They
// underpin a lot of how the framework functions and should be modified and
// preconfigured with caution.


// Base typographical styles and baseline grid. You need to define these values
// in pixels: inuitcss will convert them to more appropriate units.

$base-font-size:    16px !default;
$base-line-height:  28px !default;

// Fonts

$font-body 			: 'montserrat';
$font-head-large 	: 'kentledge';
$font-head-small 	: 'montserrat';

$font-weight-thin 			: 200;
$font-weight-body 			: 500;
$font-weight-header 		: 600;
$font-weight-head 			: 900;
$font-weight-head-small 	: 400;
$font-weight-buttons		: 500;


// Do we need to support IE8?

$oldIE: false !default;

$primary-color		: #8062D4;
$secondary-color	: #41DCAB;
$body-color			: #000000;
$white				: #FFFFFF;
$black				: #000000;
$gray-lighter		: #F7F7F7;
$gray-light			: #E9E9E9;
$gray-dark			: #C2C2C2;
$alert-color		: #DF2451;

$kl-secondary		: #44D0DA;
$kl-primary			: #FC5E6E;

$wl-primary			: #2CC3EC;
$wl-secondary		: #FFF143;

$base-radius		: 5px;

// Transitions
$base-transition-bezier 	: all 0.25s cubic-bezier(.17,.67,.83,.67);
$base-transition-linear 	: all 0.25s linear;

// Shadows
// $base-shadow		: 0px 20px 20px 0px rgba(0,0,0,0.03), 0px 1px 2px 0px rgba(0,0,0,0.1);
$base-shadow		:
	0 18px 22px -12px rgba(0,0,0,0.18);

$large-shadow		:
	0 22px 34px -17px rgba(0,0,0,0.27);

// Spacing values are determined based on your project’s global line height (i.e
// your baseline grid). It is not recommended that you modify these following
// variables (it can break your vertical rhythm), but if you need to, you can.

$base-spacing-unit: round($base-line-height) !default;


// How many times larger/smaller than the default should our spacing unit
// variants be?

$base-spacing-unit-factor-tiny:   0.25 !default;
$base-spacing-unit-factor-small:  0.5  !default;
$base-spacing-unit-factor-large:  2    !default;
$base-spacing-unit-factor-huge:   4    !default;





////////////////////////////////////////////////////////////////////////////////
//                                                                            //
//                               W A R N I N G                                //
//                                                                            //
//                  DO NOT MODIFY ANYTHING BEYOND THIS POINT                  //
//                                                                            //
////////////////////////////////////////////////////////////////////////////////


// Check that the chosen font rules are pixel numbers.

@each $_font-globals in
	  $base-font-size
	  $base-line-height {

  @if (type-of($_font-globals) == number) {

	@if (unit($_font-globals) != "px") {
	  @error "`#{$_font-globals}` needs to be a pixel value.";
	}

  } @else {
	@error "`#{$_font-globals}` needs to be a number.";
  }

}


// Check that the chosen size factors are unitless numbers.

@each $_spacing-unit in
	  $base-spacing-unit-factor-tiny
	  $base-spacing-unit-factor-small
	  $base-spacing-unit-factor-large
	  $base-spacing-unit-factor-huge {

  @if (type-of($_spacing-unit) == number) {

	@if (unitless($_spacing-unit) == false) {
	  @error "`#{$_spacing-unit}` needs to be unitless.";
	}

  } @else {
	@error "`#{$_spacing-unit}` needs to be a number.";
  }

}


// Private/framework-only reassignment. Do not alter anything below.

$base-spacing-unit-tiny:  round($base-spacing-unit * $base-spacing-unit-factor-tiny);
$base-spacing-unit-small: round($base-spacing-unit * $base-spacing-unit-factor-small);
$base-spacing-unit-large: round($base-spacing-unit * $base-spacing-unit-factor-large);
$base-spacing-unit-huge:  round($base-spacing-unit * $base-spacing-unit-factor-huge);
