/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */

.o-list-inline {
	margin-left: 0;
	list-style: none;

	&__item, > li {
		display: inline-block;
	}
}
/* Delimited list.
   ========================================================================== */
/**
 * By default, applying this class will comma separate your list items. You can
 * change the delimiter by predefining the following variable:
 */

$list-inline-delimiter: ",\00A0" !default;
.o-list-inline--delimited {
	font-size: 0;

	> .o-list-inline__item {
		@if ($oldIE= true) {
			font-size: $base-font-size;
		}
		font-size: 1rem;
	}
	
	> .o-list-inline__item + .o-list-inline__item {
		&:before {
			content: "#{$list-inline-delimiter}";
		}
	}
}