#{text-inputs()} {
	-webkit-appearance: none;
}

#{text-inputs()},
select,
textarea {

	min-height: $base-spacing-unit-large;

	display: block;
	vertical-align: middle;
	width: 100%;
	padding: 0 $base-spacing-unit-small;
	outline: 0;

	line-height: normal;
	font-weight: 400;
	@extend %small;

	border: 1px solid $gray-light;
	border-radius: $base-radius;
	box-shadow: none;

	transition: $base-transition-linear;

	&:focus,
	&:active {
		outline: none;
		border-color: $gray-dark;

		box-shadow: $base-shadow;
		color: $primary-color;
	}

	&::-webkit-input-placeholder { color: $body-color; }
	&::-moz-placeholder { color: $body-color; }
	&:-ms-input-placeholder { color: $body-color; }
	&:-moz-placeholder { color: $body-color; }
}

form {
	margin-bottom: $base-spacing-unit;
}

select {
	-webkit-appearance: none;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='#{$body-color}'><polygon points='0,0 100,0 50,50'/></svg>");
	background-size: $base-spacing-unit-small;
	background-repeat: no-repeat;
	background-position: calc(100% - #{$base-spacing-unit-small}) calc(50% + #{$base-spacing-unit-tiny / 1.7});
	background-repeat: no-repeat;
	background-color: transparent;

	line-height: $base-spacing-unit-large;

	&:focus {
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='#{$primary-color}'><polygon points='0,0 100,0 50,50'/></svg>");
	}
}

textarea {
	padding-top: $base-spacing-unit-small;
}

label {
	display: block;
	margin-bottom: $base-spacing-unit-tiny;
	color: $body-color;
}

fieldset {
	@extend %box;
	
	box-shadow: none;
	padding: $base-spacing-unit;

	legend {
		@extend .h4;

		padding: 0 $base-spacing-unit-small;
		margin-left: -$base-spacing-unit-small;
	}

	fieldset {
		padding: 0;
		border: 0;
	}
}

input,
select,
textarea {
	font-family: $font-body;
}

input[type="submit"],
input[type="reset"] {
	line-height: $base-line-height / $base-font-size;
}