.breadcrumbs {
	@extend %list-bare;

	&__separator {
		display: inline-block;
		margin: 0 $base-spacing-unit-small;
	}

	@include mq( $until: tablet ) {
		margin-bottom: $base-spacing-unit;
	}
}