#edd_checkout_form_wrap {
	#edd_show_vat_info {
		@extend .notification;
	}
}

#vat_reset,
#vat_validate {
	margin: 0px;
}

input[name="vat-number"] {
	margin-bottom: $base-spacing-unit-small;
}

.vat_number_not_given,
.vat_number_validated {
	color: $secondary-color;
}

.vat_number_not_given,
.vat_number_validated,
.vat_number_not_validated,
.vat_number_not_given {
	margin-left: $base-spacing-unit-small;
}

.vat_number_not_validated {
	color: $alert-color;
}

.vat_number_not_given {
	margin-left: 0px;
}

.vat-box {
	@extend .notification;
	@extend .notification--small;

	background-image: none;
	margin-bottom: $base-spacing-unit;
	width: 100%;
	box-shadow: none;

	&.vat-box-info {
		// @extend .notification-info;
	}

	&.vat-box-error {
		@extend .notification-error;
	}
}

#close_box {
	display: none;
}

#edd_vat_info_show {
	margin-bottom: $base-spacing-unit;
}