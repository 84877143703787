//
// Button
//

.ee-button {
	&[class*="ee-size-"] {
		border-radius: $base-radius;
	}
}

.elementor-button {
	&.elementor-size- {
		&xs { padding: 10px 14px; }
		&sm { padding: 12px 14px; }
		&md { padding: 15px 16px; }
		&lg { padding: 20px 22px; }
		&xl { padding: 25px 27px; }
	}
}