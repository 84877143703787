.footer {
	background: $gray-lighter; 
	border-top: 1px solid $gray-light;

	h5 {
		margin-bottom: $base-spacing-unit-small;
	}

	&__products {
		@include mq( desktop ) {
			margin-top: $base-spacing-unit-large;
		}

		a {
			color: $gray-dark;

			&:hover {
				color: $body-color;
			}
		}
	}

	&__by {
		margin-top: $base-spacing-unit;
		img {
			max-width: 48px;
			margin-right: $base-spacing-unit;
		}

		@include mq( $until: tablet ) {
			text-align: left;
		}
	}
}