/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */

%table,
table {
	width: 100%; /* [1] */
	text-align: left;

	border-collapse: separate;

	overflow: hidden;
	position: relative;

	@extend %box;
	
	tr {
		border-bottom: 1px solid $gray-lighter;
		background-color: $white;
		margin-left: 1px;
		margin-right: 1px;

		&:last-child { border-bottom: 0px; }
	}

	th { font-weight: 600;}

	thead,
	tfoot {
		> tr {
			background-color: $gray-lighter;
			border-bottom: 1px solid $gray-light;
		}
	}

	th, td {
		padding: $base-spacing-unit-tiny * 1.5;

		&:first-child { padding-left: $base-spacing-unit-small; }
		&:last-child:not(:first-child):not(.ee-table__cell) {
			padding-right: $base-spacing-unit-small;
			text-align: right;
		}
	}

	&.alt-rows {
		tbody {
			tr {
				&:nth-child(even) {
					background-color: $gray-lighter;
				}
			}
		}
	}

	// Table Footer
	tfoot {
		tr {
			&:not(:last-child) {
				td, th {
					border-bottom: 1px solid $gray-light;
				}
			}
		}
	}

	table {
		box-shadow: none;
		margin: 0;
	}
}