.off-canvas {
	@extend .b--secondary;
	@extend %stretch;

	position: fixed;
	overflow-y: auto;
	z-index: 20;

	@extend .o-box;
	padding-top: $base-spacing-unit-huge;

	transform: translateX(-100%);

	ul {
		font-size: 24px;
		font-weight: 400;
	}

	&__close {
		margin-bottom: $base-spacing-unit;

		&:before {
			font-size: 32px;
		}
	}
}