//
// Slick Elementor Theme
//

.skin-kentledge {
	.elementor-slick-slider {
		.slick-slider {
			box-shadow: $base-shadow;
			border-radius: $base-radius;
			border: 1px solid $kl-primary;
		}
	}
}

.elementor-slick-slider {

	// Arrows
	.slick-prev,
	.slick-next {
		&:before {
			opacity: 1;
		}

		&:hover,
		&:focus {
			box-shadow: none; //
		}
	}

	.slick-prev {
		left: -64px; //
		[dir="rtl"] & {
			right: -64px; //
		}
	}

	.slick-next {
		right: -64px; // 
		[dir="rtl"] & {
			left: -64px; //
		}
	}

	// Dots
	.slick-dotted.slick-slider {
		margin-bottom: 30px;
	}

	ul.slick-dots {
		bottom: -64px;
	}

	ul.slick-dots {
		li {
			button {
				&:hover,
				&:focus {
					box-shadow: none;

					&:before {
						opacity: 1;
					}
				}
			}
		}
	}

}