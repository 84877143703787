.typster {
	@include mq( $until: tablet ) {
		display: none;
	}
	
	display: block;

	&__controls {
		// margin-bottom: $base-spacing-unit !important;
	}

	&-preview {
		padding: $base-spacing-unit-huge 0;
		color: $kl-primary;
		font-size: 120px;
		font-weight: 500;
		font-family: 'kentledge';
		transition: none;

		text-align: center;

		margin-top: $base-spacing-unit-huge;

		&::selection { background: $kl-primary; color: $white; }
		&::-moz-selection { background: $kl-primary; color: $white; }

		&,
		&:focus {
			box-shadow: none;
			color: $kl-primary;
			border: 0;
			border-top: 2px solid;
			border-bottom: 2px solid;
			border-radius: 0;
		}
	}

	&-control {

		margin-bottom: $base-spacing-unit-large;

		@include mq( $until: tablet ) {
			margin-bottom: $base-spacing-unit;
		}

		label {
			span {
				font-weight: 500;
				margin-left: $base-spacing-unit-small;
				color: $kl-secondary;
			}
		}
		&--weight {
			ul {
				@extend %list-bare;
				margin-top: $base-spacing-unit;

				li {

					vertical-align: middle;
					cursor: pointer;

					&:hover,
					&.is--selected {
						span {
							background-color: $kl-primary
						}
					};

					span {
						width: 4px;
						height: 4px;
						border-radius: 999px;
						display: block;

						background-color: transparentize($kl-primary, 0.5);
					}
				}
			}
		}
	}
}