.notification {
	padding: $base-spacing-unit;

	background-color: transparentize($primary-color, 0.9);
	border: 1px solid transparentize($primary-color, 0.7);

	color: $body-color;

	border-radius: $base-radius;

	> a {
		color: $primary-color;

		&:hover {
			color: darken( $primary-color, 20 );
		}
	}
}

.notification--small {
	@extend %small;
	
	padding: $base-spacing-unit-small;
}

.notification--tiny {
	@extend %tiny;
	
	padding: $base-spacing-unit-tiny $base-spacing-unit-small;
}

.notification-info {

}

.notification-note {
	background-color: $gray-lighter;
	border-color: $gray-light;
}

.notification-error {
	background-color: transparentize($alert-color, 0.9);
	border-color: transparentize($alert-color, 0.7);
}

.notification-success {
	background-color: transparentize($secondary-color, 0.9);
	border-color: transparentize($secondary-color, 0.7);
}