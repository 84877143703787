.site-header {

	z-index: 10;
	box-shadow: 0px 1px 0px rgba(0,0,0,0.1);
	transition: background-color 0.15s linear;

	width: 100%;

	&, a {
		color: $body-color;
		font-weight: $font-weight-header;

		&:hover {
			color: darken( $body-color, 30 );
		}
	}

	.sub-menu-wrap {
		ul.sub-menu {
			li {
				> a {
					color: $body-color;
				}
			}
		}
	}

	h1 {
		&:not(:last-child) {
			margin-bottom: 0; 
		}
	}

	&.floating {
		@extend %stretch;

		bottom: auto;
		position: fixed;

		@extend .b--white;

		&.is--attached {
			@extend .b--primary;

			.site-title {
				a[rel="home"] {
					background-image: url( 'assets/img/interface/logo-inverted.svg' );
				}
			}

			.sub-menu {
				@extend .b--white;
			}
		}
	}

	&.is--hidden {
		.sub-menu-wrap {
			display: none !important;
		}
	}

	&__wrapper {

		// Flex
		display: flex;
		justify-content: space-between;
		align-items: center;

		height: 60px;
	}

	ul {
		@extend .o-list-bare;
	}

	ul, p {
		margin: 0;
	}

	.site-branding {

		display: flex;
		align-items: center;

		.site-title {
			@include mq( mobile ) {
				padding-right: $base-spacing-unit-large;
			}

			a[rel="home"] {
				width: 102px;
				height: 13px;

				background: url( 'assets/img/interface/logo.svg' ) no-repeat;
				display: block;

				> i { display: none; }
			}
		}
	}
}

.site-header--secondary {
	@extend .u-clearfix;
	
	color: $white;

	padding: 80px 0 40px 0;

	&:before {
		content: "";

		height: 80px;
		width: 100%;

		display: block;
		position: relative;
	}
}

.site-header--kb {
	display: flex;
	padding: $base-spacing-unit 0;

	@include mq( desktop ) {
		padding: $base-spacing-unit-large 0;
	}
	
	&::before {
		content: none;
	}

	.search-form {
		margin: 0;
	}

	.breadcrumbs {
		margin-bottom: $base-spacing-unit-small;
	}
}

body.logged-in {
	
}