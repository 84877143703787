
$colors: (
	primary 		: $primary-color,
	secondary 		: $secondary-color,
	body 			: $body-color,
	white 			: $white,
	black 			: $black,
	gray-light 		: $gray-light,
	gray-lighter 	: $gray-lighter,
	gray-dark 		: $gray-dark,
	alert 			: $alert-color,
	kl-primary		: $kl-primary,
	kl-secondary	: $kl-secondary,

	wl-primary		: $wl-primary,
	wl-secondary	: $wl-secondary,
) !default;

@each $color-name, $color in $colors {

	.b--#{$color-name} {
		background-color: $color;
		
		&, a, p, #{headings()}, ul, li {
			color: foreground( $color );
		}

		a {
			&:hover {
				color: foreground( $color, $light: rgba( 255,255,255,0.5 ) );
			}
		}

		&%button {
			background-color: $color;
			color: foreground( $color );

			.ee-button-text {
				color: foreground( $color );
			}

			&:hover {
				background-color: darken( $color, 10 );
				color: foreground( $color );
			}
		}
	}

	.f--#{$color-name} {
		&, a, p, #{headings()} {
			color: $color !important;
		}
	}
}