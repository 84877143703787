.kb {
	&-list {
		&__topic {
			&__link {
				display: flex !important;
				justify-content: space-between;
				align-items: center;

				font-weight: 600;
			}
		}

		&__all {
			> a {
				color: $secondary-color;
				font-weight: 600;
			}
		}
	}
}

.tax-topic {

	.kb {
		&-topic {
			&__header {
				max-width: 640px;

				p {
					@extend %intro;
				}
			}
		}

		&_article {
			.entry-header {
				margin-bottom: 0px;
			}
		}
	}

	.box--link {
		display: flex;

		> * { flex-grow: 1; }

		&:hover {
			.entry-title { color: $primary-color; }
		}
	}
}

.post-type-archive-kb_article {
	.site-header--secondary {
		&:before {
			content: none;
		}
	}
}