.edd_sl_license_row {
	input.edd_sl_license_key {
		@extend code;
	}
}

.edd_sl_item_name {
	font-weight: 600;

	.edd_sl_key_price_option {
		font-weight: 400;
	}
}

.edd_sl_show_key {
	img { display: none; }

	@extend .fa;
	@extend .fa-key;
}

.edd-sl-manage-license-header {
	@extend .h3;
}