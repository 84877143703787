// KENTLEDGE

// Black

@include font-face( 'kentledge', 'Kentledge_Black', 900, normal );
@include font-face( 'kentledge', 'Kentledge_Black-Italic', 900, italic );

// Heavy

@include font-face( 'kentledge', 'Kentledge_Heavy', 800, normal );
@include font-face( 'kentledge', 'Kentledge_Heavy-Italic', 800, italic );

// Extra Bold

@include font-face( 'kentledge', 'Kentledge_Extra-Bold', 700, normal );
@include font-face( 'kentledge', 'Kentledge_Extra-Bold-Italic', 700, italic );

// Bold

@include font-face( 'kentledge', 'Kentledge_Bold', 600, normal );
@include font-face( 'kentledge', 'Kentledge_Bold-Italic', 600, italic );

// Medium

@include font-face( 'kentledge', 'Kentledge_Medium', 500, normal );
@include font-face( 'kentledge', 'Kentledge_Medium-Italic', 500, italic );

// Regular

@include font-face( 'kentledge', 'Kentledge_Regular', 400, normal );
@include font-face( 'kentledge', 'Kentledge_Regular-Italic', 400, italic );

// Light

@include font-face( 'kentledge', 'Kentledge_Light', 300, normal );
@include font-face( 'kentledge', 'Kentledge_Light-Italic', 300, italic );

// Thin

@include font-face( 'kentledge', 'Kentledge_Thin', 200, normal );
@include font-face( 'kentledge', 'Kentledge_Thin-Italic', 200, italic );

// WILLIE

// Regular

@include font-face( 'willie', 'Willie-Regular', 700, normal );


body {
	font-family: $font-body;
	font-size: $base-font-size;
	font-weight: $font-weight-body;
	color: $body-color;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;

	color: $primary-color;
	transition: $base-transition-linear;

	&:hover {
		color: darken( $primary-color, 20 );
	}

	> i {
		display: none;
	}
}

p {
	// letter-spacing: -0.02em;

	&.intro {
		@extend %intro;
		font-weight: 500;
	}

	&.small {
		@extend %small;
	}
}

.thin {
	font-weight: $font-weight-thin;
	display: inline-block;
}

.emphasize {
	display: inline-block;
	// font-style: italic;
}

.h0 { @extend %h0; }
h1, .h1 { @extend %h1; }
h2, .h2 { @extend %h2; }
h3, .h3 { @extend %h3; }
h4, .h4 { @extend %h4; }
h5, .h5 { @extend %h5; }
h6, .h6 { @extend %h6; }

#{headings()},
#{headings($prefix:'.')} {
	font-family: $font-head-large;
	font-weight: $font-weight-head;
	letter-spacing: -0.03em;
	line-height: 1.4;
	
	color: $body-color;

	> a {
		color: $body-color;
		&:hover { color: $black; }
	}

	.entry-content & {
		// @include baseline();
	}
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
	// margin-bottom: $base-spacing-unit-small;
}

h5, .h5, h6, .h6 {
	font-family: $font-head-small;
	font-weight: $font-weight-head-small;
}

.ff-kentledge {
	*
	#{headings()} {
		font-family: 'kentledge' !important;
	}
}

.ff-willie {
	*
	#{headings()} {
		font-family: 'willie' !important;
	}
}

.ff-w-100 { font-weight: 100; }
.ff-w-200 { font-weight: 200; }
.ff-w-300 { font-weight: 300; }
.ff-w-400 { font-weight: 400; }
.ff-w-500 { font-weight: 500; }
.ff-w-600 { font-weight: 600; }
.ff-w-700 { font-weight: 700; }
.ff-w-800 { font-weight: 800; }
.ff-w-900 { font-weight: 900; }

blockquote {
	text-align: left;
	position: relative;
	padding-left: 40px;

	&:before {
		content: "\201C";
		position: absolute;
		left: 0;
		top: 0;

		font-family: 'jubilat', sans-serif;

		line-height: 1;

		width: 40px;
		height: 40px;

		color: $gray-light;

		@extend %h2;
	}

	&:after {
		content: "";
		position: absolute;
		top: 2em;
		left: 0.5em;
		bottom: 0;

		background-color: $gray-light;
		
		width: 1px;
	}
}

small {
	@extend %small;
}

strong,
b {
	font-weight: 600;
}