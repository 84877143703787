#edd_profile_first_name_wrap,
#edd_profile_last_name_wrap,
#edd_profile_billing_address_line_1_wrap,
#edd_profile_billing_address_line_2_wrap,
#edd_profile_billing_address_country_wrap,
#edd_profile_billing_address_state_wrap,
#edd_profile_password_wrap,
#edd_profile_confirm_password_wrap,
#edd_profile_billing_address_wrap {
	@extend %edd-form-columns;
}

%edd-form-columns {
	@include mq( tablet ) {
		width: calc(50% - #{$base-spacing-unit / 2});
		float: left;

		display: inline-block;

		& + & {
			margin-left: $base-spacing-unit;
			float: right;
		}
	}
}