///* ========================================================================
//   #MIXINS
//   ======================================================================== */

// Global mixins available to the entire project. Define file-specific
// mixins inside the file to which they relate.





// Generate a font-size and baseline-compatible line-height.

@mixin font-size($font-size, $line-height: auto, $important: false) {

	@if ($important == true) {
		$important: !important;
	} @elseif ($important == false) {
		$important: null;
	} @else {
		@error "#{$important} needs to be `true` or `false`."
	}

	@if ($oldIE == true) {
		font-size: $font-size $important;
	}

	font-size: ($font-size / $base-font-size) * 1rem $important;

	@if ($line-height == 'auto') {
		line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size) $important;
	}

	@else {

		@if (type-of($line-height) == number or $line-height == 'inherit' or $line-height == 'normal') {
			line-height: $line-height $important;
		}

		@elseif ($line-height != 'none' and $line-height != false) {
			@error "D’oh! ‘#{$line-height}’ is not a valid value for `line-height`.";
		}

	}

}

// Mixin to create a declaration whose value is a rem unit. Also provide pixel
// fallback.

@mixin rem($property, $value) {

	@if ($oldIE == true) {
		#{$property}: $value;
	}

	#{$property}: rem($value);
}

.simple-usage {
	@include rem(padding, 10px);
}




// Mixin to drop micro clearfix into a selector. Further reading:
// http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
//
// .usage {
//   @include clearfix();
// }

@mixin clearfix() {

	&:after {
		content: "" !important;
		display: block !important;
		clear: both !important;
	}

}

// Generate `:hover` and `:focus` styles in one go.

@mixin hocus() {
	&:hover,
	&:focus {
		@content;
	}
}

// Mixin to quickly apply accessible hiding to elements.

@mixin hidden-visually() {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

@mixin font-face( $font-name, $file-name, $font-weight, $font-style ) {
	@font-face {
		font-family: #{$font-name};
		src: 	url('assets/fonts/#{$font-name}/#{$file-name}.eot?#iefix') format('embedded-opentype'),
				url('assets/fonts/#{$font-name}/#{$file-name}.woff') format('woff'),
				url('assets/fonts/#{$font-name}/#{$file-name}.ttf')  format('truetype'),
				url('assets/fonts/#{$font-name}/#{$file-name}.svg##{$file-name}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
	}
}
