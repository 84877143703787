// Stacked menus

%stacked-nav {
	@extend %data-list;
	@extend %box;

	background-color: $white;

	li {
		&.current-menu-item {
			> a { color: $primary-color; }
		}
	}
}

%sidebar-nav {
	@extend %stacked-nav;

	li {
		padding: 0;

		a {
			font-weight: 500;
			width: 100%;
			padding: $base-spacing-unit-tiny $base-spacing-unit-small;
			color: $body-color;

			&:hover {
				color: $primary-color;
				background-color: darken( $white, 2 );
			}

			.fa {
				width: 18px;
				text-align: center;
			}
		}
	}

	.sub-menu {
		@extend %data-list;

		background-color: $gray-lighter;
		border-top: 1px solid $gray-light;

		li {
			padding: 0;
			
			a { padding-left: calc( 18px + #{$base-spacing-unit} ); }
		}
	}
}

.sidebar-nav {
	@extend %sidebar-nav;

	&.sidebar-nav--spaced {
		li {
			a {
				padding: $base-spacing-unit-small $base-spacing-unit-small + $base-spacing-unit-tiny;
			}
		}
	}
}

// Header Navigation

ul.nav-menu {
	li {
		position: relative;

		&:hover {
			> ul {
				// display: block;
			}
		}

		a { display: block; }
	}

	.sub-menu-wrap {
		z-index: 10;
		visibility: hidden;

		position: absolute;
		left: 0;

		min-width: 150px;

		transform: translateY(-$base-spacing-unit-small);

		&:before {
			content: "";

			display: block;

			width: 100%;
			height: $base-spacing-unit-small;

			position: relative;
		}

		& .sub-menu-wrap {
			top: -1px;
			left: 100%;
			z-index: 99;

			&:before {
				content: none;
			}
		}
	}

	ul {
		@extend %sidebar-nav;

		li {
			white-space: nowrap;
			// overflow: hidden;

			a {
				background-color: $white;
				color: $body-color;
			}
		}

		&.sub-menu {
			li a {
				padding: $base-spacing-unit-tiny $base-spacing-unit-small;
			}
		}
	}
}