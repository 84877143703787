.wpas {
	&-ticket-buttons-top {
		font-size: 0;
		margin-left: -$base-spacing-unit-small;
		margin-bottom: $base-spacing-unit;

		a {
			display: inline-block;
			font-size: 1rem;

			margin-left: $base-spacing-unit-small;
		}
	}

	&-label {
		@extend .label;
		color: $white;
	}

	&-table {
		tr {

		}
	}

	&-ticket {
		&-buttons-top {
			display: none;
		}

		&-replies {
			@extend .alt-rows;

			box-shadow: none;

			tr {
				&:first-child {
					td {
						border-top: 0px;
					}
				}

				&.wpas-status-unread {
					&, &:nth-child(even) {
						background-color: transparentize($secondary-color, 0.9);
					}

					td {
						border-color: $secondary-color;
					}
				}
			}

			td {
				border-top: 1px solid $gray-light;

				.wpas-user-profile {
					margin: $base-spacing-unit;
				}

				.wpas-reply {
					&-meta {
						display: flex;

						margin-left: -$base-spacing-unit-small;
						margin-bottom: $base-spacing-unit-small;
						margin-top: $base-spacing-unit;

						@extend %small;

						color: $gray-dark;

						> * {
							margin-left: $base-spacing-unit-small;
						}
					}

					&-content {
						@extend blockquote;

						p { font-weight: 400; }
					}
				}
			}
		}
	}

	&-user-profile {
		border-radius: 9999px;
		overflow: hidden;
	}

	&-form {
		&-group {
			margin-bottom: $base-spacing-unit-small;

			&#wpas_files_wrapper {
				@extend %file-field;
			}
		}

		.checkbox {
			margin-bottom: $base-spacing-unit;
		}
	}

	&-alert,
	&-help-block {
		@extend .notification;
	}

	&-help-block {
		@extend .notification--tiny;
		@extend .notification-note;

		margin-top: $base-spacing-unit-tiny;
	}

	&-btn[name="wpas-submit"] {
		@extend .b--secondary;
	}

	&-row {
		@extend .o-layout;

		> * {
			@extend .o-layout__item;

			&.wpas-one-third {
				@extend .u-1\/3\@tablet;
			}
		}
	}
}

#wpas_ticketlist_filters {
	margin-bottom: $base-spacing-unit;
}