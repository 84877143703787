
.o-prefix__content {
	#{text-inputs()},
	select {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}
}

label {
	input[type="radio"],
	input[type="checkbox"] {
		@include hidden-visually();
	}

	&.label--radio,
	&.label--checkbox {
		display: flex;
		align-items: center;

		cursor: pointer;
		position: relative;

		> * {
			vertical-align: middle;
		}

		&:before,
		&:after {
			content: "";

			position: relative;
			display: inline-block;
			vertical-align: middle;

			width: 16px;
			height: 16px;

			border-radius: $base-radius;

			transition: $base-transition-linear;
		}

		&:before {
			content: "";

			position: relative;
			display: inline-block;
			vertical-align: middle;

			margin-right: $base-spacing-unit-small;

			width: 24px;
			height: 24px;
			flex-shrink: 0;

			border: 1px solid $gray-light;
		}

		&:after {
			position: absolute;
			left: 0;
			top: 50%;

			margin: 0 4px;

			transform: scale(0) translateY(-50%);
			transform-origin: 50% 0%;

			border-radius: $base-radius * 0.8;

			background-color: $primary-color;
		}

		&.is--checked {
			&:after {
				transform: scale(1) translateY(-50%);
			}

			&:before {
				border-color: $primary-color;
				box-shadow: $base-shadow;
			}
		}

		&:not(.is--checked):hover {
			&:after {
				transform: scale(0.5) translateY(-50%);
			}
		}
	}

	&.label--radio {
		&:before,
		&:after {
			border-radius: 999px;
		}
	}
}

.search-form {
	input[type="text"] {
		padding-left: $base-spacing-unit-large - $base-spacing-unit-tiny;
		border: 1px solid $gray-light;

		font-size: 18px;

		&:focus { border-color: $primary-color; }
	}

	label[for="s"] {
		position: relative;
		.fa {
			position: absolute;
			margin: 0 $base-spacing-unit-small;

			left: 0;
			@extend %vertical-middle;
		}
	}
}

%file-field {
	label {
		@extend .button;
		@extend %button--icon;

		&:before {
			content: "+";

			padding-right: $base-spacing-unit-small;
			font-size: 1.3em;
			line-height: 1;

			@extend .ff-w-700;
		}

		* { pointer-events: none; }
	}

	input[type="file"] {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;

		& + .file-selection-notice {
			color: $gray-dark;
			margin-left: $base-spacing-unit-small;
		}
	}
}

.file-field {
	@extend %file-field;
}