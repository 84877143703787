.box {
	@extend %box;
	@extend .o-box;
	
	margin-bottom: $base-spacing-unit;
}

.box--link {
	align-items: center;
	
	img {
		max-width: 46px;
		transition: $base-transition-linear;
	}
	&:hover {
		border-color: $primary-color;
		box-shadow: none;

		img { opacity: 1; }
	}
}