/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * If '$responsive-hidden-until' or '$responsive-hidden-from' are true,
 * classes that depond on breakpoints will be created, for example:
 *
 *	<div class="u-hidden-visually-until-desktop">
 *     <div class="u-width-1/2">
 *     </div>
 *     <div class="u-width-1/2">
 *     </div>
 *  </div>
 *
 *
 *	<div class="u-hidden-visually-from-tablet">
 *     <div class="u-width-1/2">
 *     </div>
 *     <div class="u-width-1/2">
 *     </div>
 *  </div>
 *
 */

 $responsive-hidden-visually-until:	false !default;
 $responsive-hidden-visually-from:	false !default;

.u-hidden-visually {
  @include hidden-visually();
}

@if (variable-exists(mq-breakpoints) and variable-exists(responsive-hidden-visually-until)) {
	@if ($responsive-hidden-visually-until == true) {
		@each $bp-name, $bp-value in $mq-breakpoints {
			@include mq($until: $bp-name) {
				.u-hidden-visually-until-#{$bp-name} {
					@include hidden-visually();
				}
			}
		}
	}
}

@if (variable-exists(mq-breakpoints) and variable-exists(responsive-hidden-visually-from)) {
	@if ($responsive-hidden-visually-from == true) {
		@each $bp-name, $bp-value in $mq-breakpoints {
			@include mq($from: $bp-name) {
				.u-hidden-visually-from-#{$bp-name} {
					@include hidden-visually();
				}
			}
		}
	}
}

/**
 * Hide visually and from screen readers.
 * If '$responsive-hidden-until' or '$responsive-hidden-from' are true,
 * classes that depond on breakpoints will be created, for example:
 *
 *	<div class="u-hidden-until-desktop">
 *     <div class="u-width-1/2">
 *     </div>
 *     <div class="u-width-1/2">
 *     </div>
 *  </div>
 *
 *
 *	<div class="u-hidden-from-tablet">
 *     <div class="u-width-1/2">
 *     </div>
 *     <div class="u-width-1/2">
 *     </div>
 *  </div>
 *
 *
 */

 $responsive-hidden-until: false !default;
 $responsive-hidden-from:  false !default;


.u-hidden {
	display: none !important;
}

@if (variable-exists(mq-breakpoints) and variable-exists(responsive-hidden-until)) {
	@if ($responsive-hidden-until == true) {
		@each $bp-name, $bp-value in $mq-breakpoints {
			@include mq($until: $bp-name) {
				.u-hidden-until-#{$bp-name} {
					display: none !important;
				}
			}
		}
	}
}

@if (variable-exists(mq-breakpoints) and variable-exists(responsive-hidden-from)) {
	@if ($responsive-hidden-from == true) {
		@each $bp-name, $bp-value in $mq-breakpoints {
			@include mq($from: $bp-name) {
				.u-hidden-from-#{$bp-name} {
					display: none !important;
				}
			}
		}
	}
}
