/* ==========================================================================
   #WRAPPERS
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */

$wrapper-width: 		1200px !default;
$wrapper-compact-width: 1000px !default;
$wrapper-tiny-width: 	800px !default;

@if (type-of($wrapper-width) != number) {
	@error "#{$wrapper-width} needs to be a number."
}
.o-wrapper {
	@include clearfix();
	.o-wrapper__content {
		margin-right: auto;
		margin-left: auto;
		max-width: $wrapper-width;
		width: 90%;
	}

	&--compact {
		.o-wrapper__content {
			max-width: $wrapper-small-width;
		}
	}

	&--tight {
		.o-wrapper__content {
			max-width: $wrapper-tiny-width;
		}
	}
}

/* Size variants.
========================================================================== */

.o-wrapper--tiny {
	padding-right: $base-spacing-unit-tiny;
	padding-left: $base-spacing-unit-tiny;
}
.o-wrapper--small {
	padding-right: $base-spacing-unit-small;
	padding-left: $base-spacing-unit-small;
}
.o-wrapper--normal {
	padding-right: $base-spacing-unit;
	padding-left: $base-spacing-unit;
}
.o-wrapper--large {
	padding-right: $base-spacing-unit-large;
	padding-left: $base-spacing-unit-large;
}
.o-wrapper--huge {
	padding-right: $base-spacing-unit-huge;
	padding-left: $base-spacing-unit-huge;
}