.label {
	padding: 3px 10px;
	border-radius: 5px;

	@extend %small;

	display: inline-block;
	vertical-align: middle;

	&--tiny {
		font-size: 12px;
		padding: 2px 8px 3px;
		border-radius: 3px;

		min-width: 20px;
		text-align: center;

		line-height: 1.5;
	}

	&--xsmall {
		font-size: 10px;
		padding: 2px 6px 3px;
		border-radius: 3px;

		min-width: 20px;
		text-align: center;

		line-height: 1.5;
	}

	&--updated,
	&--fresh,
	&--unique,
	&--new {
		color: $white;
	}

	&--updated,
	&--fresh {
		background-color: #f241b4;
	}

	&--unique {
		background-color: #8062d4;
	}

	&--new {
		background-color: #41dcab;
	}
}