%spinner {
	/*Code from previous step*/
	width:40px;
	height:40px;
	border:4px solid transparentize($primary-color, 0.8);
	border-top:4px solid $primary-color;
	border-radius:50%;
	display: block;

	/*Default animation code*/
	transition-property: transform;
	animation-name: rotate; /*Call to @keyframe rule*/
	animation-duration: 0.5s; /*Change to speed up or slow down the animation*/
	animation-iteration-count: infinite; /*Make the animation go forever*/
	animation-timing-function: linear;
}
 
/*Default @keyframes rule*/
@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}