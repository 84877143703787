.alignnone {
	margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
	display: block;
	margin: 5px auto 5px auto;
}

.alignright {
	float:right;
	margin: 5px 0 20px 20px;
}

.alignleft {
	float: left;
	margin: 5px 20px 20px 0;
}

a img {
	&.alignright {
		float: right;
		margin: 5px 0 20px 20px;
	}

	&.alignnone {
		margin: 5px 20px 20px 0;
	}

	&.alignleft {
		float: left;
		margin: 5px 20px 20px 0;
	}

	&.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

.wp-caption {

	.single-kb_article & {
		background: #fff;
		border: 1px solid #f0f0f0;	
		margin-bottom: $base-spacing-unit !important;
	}
	
	max-width: 96%; /* Image does not overflow the content area */
	padding: 5px 3px 10px;
	margin-bottom: $base-spacing-unit;
	text-align: center;

	&.alignnone {
		margin: 5px 20px 20px 0;
	}

	&.alignleft {
		margin: 5px 20px 20px 0;
	}

	&.alignright {
		margin: 5px 0 20px 20px;
	}

	img {
		border: 0 none;
		height: auto;
		margin: 0;
		max-width: 98.5%;
		padding: 0;
		width: auto;
	}

	figcaption {
		font-size: 11px;
		line-height: 17px;
		margin: 0;
		padding: $base-spacing-unit-small 4px 5px;
	}
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
		white-space: nowrap;
	height: 1px;
	width: 1px;
	overflow: hidden;

	&:focus {
		background-color: #f1f1f1;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		color: #21759b;
		display: block;
		font-size: 14px;
		font-size: 0.875rem;
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}

.elementor-widget-theme-post-content {
	.wp-block-image {
		margin: $base-spacing-unit-large 0 $base-spacing-unit-large 0 !important;

		img {
			border-radius: $base-radius;
		}
	}
}

.wp-block {
	&-preformatted {
		padding: $base-spacing-unit;
		background-color: $gray-lighter;
		overflow-x: auto;
		font-size: 15px;
		color: $black;
		margin: $base-spacing-unit-large 0;
		border: 1px solid #e9e9e9;
		border-radius: $base-radius;
	}

	&-table {
		box-shadow: none;
	}

	&-separator {
		margin: $base-spacing-unit-large 0 !important;
	}
}