/* ==========================================================================
   #SPACING
   ========================================================================== */

/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 */

$responsive-spacings-until: false !default;
$responsive-spacings-from:  false !default;

$spacing-directions: (
  null,
  '-top',
  '-right',
  '-bottom',
  '-left',
);

$spacing-properties: (
  'padding',
  'margin'
);

$spacing-sizes: (
  null: $base-spacing-unit,
  '-tiny': $base-spacing-unit-tiny,
  '-small': $base-spacing-unit-small,
  '-large': $base-spacing-unit-large,
  '-huge': $base-spacing-unit-huge,
  '-none': 0
) !default;

@each $property in $spacing-properties {

  @each $direction in $spacing-directions {

    @each $size, $value in $spacing-sizes {

		.u-#{$property}#{$direction}#{$size} {
          #{$property}#{$direction}: $value !important;
        }

			@if (variable-exists(mq-breakpoints)) {
				@if (variable-exists(responsive-spacings-until)) {
					@if ( $responsive-spacings-until == true) {
						@each $bp-name, $bp-value in $mq-breakpoints {
						  @include mq($until: $bp-name) {
							  .u-#{$property}#{$direction}#{$size}-until-#{$bp-name} {
								  #{$property}#{$direction}: $value !important;
							  }
						  }
					  }
					}
				}
			}

			@if (variable-exists(mq-breakpoints)) {
				@if (variable-exists(responsive-spacings-from)) {
					@if ($responsive-spacings-from == true) {
						@each $bp-name, $bp-value in $mq-breakpoints {
				  			@include mq($from: $bp-name) {
				  				.u-#{$property}#{$direction}#{$size}-from-#{$bp-name} {
				  					#{$property}#{$direction}: $value !important;
				  				}
				  			}
						}
					}
				}
			}
    	}
  	}
}
