//
// Accordion
//

.elementor-accordion {
	@extend %box;

	border: 0px;
	box-shadow: none;

	.elementor-accordion-item {
		border: 0px;

		+ .elementor-accordion-item {
			border-top: 1px solid $gray-light;
		}
	}

	.elementor-tab-title {
		font-weight: 600;
		line-height: $base-line-height;
		display: flex;

		.elementor-accordion-icon {
			margin-right: $base-spacing-unit;

			&.elementor-accordion-icon- {

				&right {
					
				}

				&left {
					
				}
			}

			.fa {
				position: relative;

				width: 24px;
				height: 24px;
				border-radius: 999pc;

				background-color: $gray-lighter;
				text-align: center;

				&:before {
					content: '\f067';
					position: absolute;
					font-weight: 100;

					@extend %place-center;
				}
			}
		}

		&.elementor-active {

			&:focus { outline: none; }

			.elementor-accordion-icon {

				.fa {
					background-color: $primary-color;
					transform: rotate(45deg);

					&:before {
						content: '\f067';
						color: $white;
					}
				}
			}
		}
	}

	.elementor-tab-content {
	    border-color: $gray-light;

	    @include mq( desktop ) {
	    	padding-left: 68px;
	    }
	}
}