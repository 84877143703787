%vertical-middle {
	top: 50%;
	transform: translateY(-50%);
}

%horizontal-middle {
	left: 50%;
	transform: translateX(-50%);
}

%place-center {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

%spread {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

// NOTE: This is not at all useful
%pseudo-element {
	content: "";
	display: block;
}

%list-bare {
	margin: 0;
	padding: 0;
	list-style: none;
}

%pseudo-element {
	content: "";
	display: block;
	position: absolute;
}

%stretch {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.shadow,
%shadow {
	box-shadow: $base-shadow;
}

.large-shadow,
%large-shadow {
	box-shadow: $large-shadow;
}

.video-shadow,
%video-shadow {
	.elementor-extras-html5-video-wrapper,
	.elementor-image img { box-shadow: $large-shadow; }
}

%box {
	border: 1px solid $gray-light;
	border-radius: $base-radius;
	
	@extend %shadow;

	%data-list {
		li {
			padding-left: $base-spacing-unit-small;
			padding-right: $base-spacing-unit-small;
		}
	}
}

.align-left { text-align: left; }
.align-center { text-align: center; }
.align-right { text-align: right; }

%block,
.block {
	display: block;
	width: 100%;
}
