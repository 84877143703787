// Grid

.elementor-row {
	> .elementor-column {
		> .elementor-element-populated {
			.elementor-column-gap-no > & {
				padding: 0;
			}

			.elementor-column-gap-narrow  > & {
				padding: 0 $base-spacing-unit-tiny / 2;
			}

			.elementor-column-gap-default > & {
				padding: 0 $base-spacing-unit-small / 2;
			}

			.elementor-column-gap-extended > & {
				padding: 0 $base-spacing-unit / 2;
			}

			.elementor-column-gap-wide > & {
				padding: 0 $base-spacing-unit-large / 2;
			}

			.elementor-column-gap-wider > & {
				padding: 0 $base-spacing-unit-huge / 2;
			}
		}
	}
}

.elementor-column-gap-no > .elementor-row {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
.elementor-column-gap-narrow > .elementor-row {
    width: calc(100% + #{$base-spacing-unit-tiny});
    margin-left: -$base-spacing-unit-tiny / 2;
    margin-right: -$base-spacing-unit-tiny / 2;
}
.elementor-column-gap-default > .elementor-row {
    width: calc(100% + #{$base-spacing-unit-small});
    margin-left: -$base-spacing-unit-small / 2;
    margin-right: -$base-spacing-unit-small / 2;
}
.elementor-column-gap-extended > .elementor-row {
    width: calc(100% + #{$base-spacing-unit});
    margin-left: -$base-spacing-unit / 2;
    margin-right: -$base-spacing-unit / 2;
}
.elementor-column-gap-wide > .elementor-row {
    width: calc(100% + #{$base-spacing-unit-large});
    margin-left: -$base-spacing-unit-large / 2;
    margin-right: -$base-spacing-unit-large / 2;
}
.elementor-column-gap-wider > .elementor-row {
    width: calc(100% + #{$base-spacing-unit-huge});
    margin-left: -$base-spacing-unit-huge / 2;
    margin-right: -$base-spacing-unit-huge / 2;
}
